<template>
    <BaseModal
        :modalWidth="'820px'"
        class="reconciliation-modal-check-address-program" 
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div class="reconciliation-modal-check-address-program__body">
                <div class="reconciliation-modal-check-address-program__section reconciliation-modal-check-address-program__title">
                    Номер договора
                </div>
                <div class="reconciliation-modal-check-address-program__section">
                    <div class="reconciliation-modal-check-address-program__contract-list">
                        <div
                            v-for="contractKey in contractsKeys"
                            :key="contractKey"
                            class="reconciliation-modal-check-address-program__contract-list-item"
                        >
                            <BasePreloaderSkeleton 
                                v-if="isLoading"
                                class="reconciliation-modal-check-address-program__preloader"
                            />
                            <BaseTextInput
                                :value="contractsBuffer[contractKey].value"
                                :view="'primary'"
                                :placeholder="'Введите номер договора'"
                                :delayInputLazy="600"
                                :isValid="contractsBuffer[contractKey].isValid"
                                :errorMessage="contractsBuffer[contractKey].errorMessage"
                                @onInputLazy="onInputLazyContract($event, contractKey)"
                            />
                            <div 
                                class="reconciliation-modal-check-address-program__contract-add"
                                @click="addContract"
                            >
                                <IconPlus />
                            </div>
                        </div>
                        <div>{{ infoMsg }}</div>
                    </div>
                </div>
                <div class="reconciliation-modal-check-address-program__actions">
                    <div class="reconciliation-modal-check-address-program__action">
                        <BasePreloaderSkeleton v-if="isLoading" />
                        <BaseButton
                            view="border"
                            @click="onCloseModal"
                        >
                            Закрыть
                        </BaseButton>
                    </div>
                    <div
                        v-if="isCheckAddressProgramBtn"
                        class="reconciliation-modal-check-address-program__action"
                    >
                        <BasePreloaderSkeleton v-if="isLoading" />
                        <BaseButton
                            :disabled="!isContractsValid"
                            view="secondary"
                            @click="checkAddressProgram"
                        >
                            Свериться
                        </BaseButton>
                    </div>
                </div>
            </div>
            <slot />
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../../Base/BaseModal.vue';
import BaseTextInput from '../../Base/BaseTextInput.vue';
import BasePreloaderSkeleton from '../../Base/BasePreloaderSkeleton.vue';
import BaseButton from '../../Base/BaseButton.vue';
import IconPlus from '../../Icons/IconPlus.vue';
export default {
    name: 'ReconciliationModalCheckAddressProgram',
    components: {
        BaseModal,
        BaseTextInput,
        BasePreloaderSkeleton,
        BaseButton,
        IconPlus
    },
    props: {
        contracts: {
            type: Object,
            default: () => ({
                1: {
                    value: '', 
                    isValid: true, 
                    errorMessage: ''
                }
            })
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        infoMsg: {
            type: String,
            default: ''
        }
    },
    inject: {
        validationContractNumber: {
            from: 'validationContractNumber',
            default: async (contractValue = '') => {
                console.error(`don't provide validationContractNumber with contractValue: ${contractValue}`);
                return {isValid: false, errorMessage: ''};
            }
        }
    },
    data: () => ({
        contractsBuffer: {},
    }),
    watch: {
        contracts() {
            this.contractsBuffer = {...this.contracts};
        }
    },
    mounted() {
        this.contractsBuffer = {...this.contracts};
    },
    computed: {
        contractsKeys() {
            return Object.keys(this.contractsBuffer);
        },
        contractsKeysLength() {
            return this.contractsKeys.length;
        },
        contractsKeysWithValue() {
            return this.contractsKeys.filter(contractKey => String(this.contractsBuffer[contractKey]?.value ?? '') !== '');
        },
        contractsValues() {
            return this.contractsKeysWithValue.map(contractKey => String(this.contractsBuffer[contractKey]?.value ?? ''));
        },
        contractsKeysUnvalid() {
            return this.contractsKeys.filter(contractKey => Boolean(!this.contractsBuffer[contractKey]?.isValid ?? false));
        },
        isContractsValid() {
            return this.contractsKeysWithValue.length > 0 && this.contractsKeysUnvalid.length === 0;
        },
        isCheckAddressProgramBtn() {
            return this.infoMsg === '';
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('onCloseModal');
        },
        addContract() {
            this.contractsBuffer = {
                ...this.contractsBuffer,
                [this.contractsKeysLength + 1] : {
                    value: '', 
                    isValid: true, 
                    errorMessage: ''
                }
            };
        },
        async onInputLazyContract(contractValue = '', contractKey = '') {
            this.$emit('update:isLoading', true);
            const validationRes = await this.validationContractNumber(contractValue);
            this.contractsBuffer = {
                ...this.contractsBuffer,
                [contractKey]: {
                    value: String(contractValue),
                    isValid: Boolean(validationRes?.isValid ?? false), 
                    errorMessage: String(validationRes?.errorMessage ?? '')
                }
            };
            this.$emit('update:isLoading', false);

            this.$emit('update:contracts', this.contractsBuffer);
        },
        checkAddressProgram() {
            this.$emit('checkAddressProgram', this.contractsValues);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-modal-check-address-program {
        &__title {
            font-size: 20px;
            font-weight: 500;
        }

        &__section {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &__contract {
            &-list {
                &-item {
                    display: flex;
                    margin-bottom: 30px;
                    position: relative;
                }
            }

            &-add {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        &__actions {
            display: flex;
            justify-content: end;
        }

        &__action {
            position: relative;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        &__preloader {
            z-index: 1;
        }
    }
</style>