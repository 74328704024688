import ServiceApi from "../ServiceApi";
import ServiceReconciliationFilter from "./ServiceReconciliationFilter";
import lodash from "lodash";
import {
    tableColumnsTotalScheme,
    tableColumnsScheme,
    tableRowsValuesTotalScheme,
} from "../../schemes/SchemeLayoutReconciliationTables";

export default class ServiceReconciliationTable {
    static _microserviceName = "free";

    static _requestRouts = {
        getTable: {
            offers: "/new_map/offer-table",
            kits: "/new_map/set-table",
            more: "/new_map/offer-side-table",
            approved: "/new_map/approve-table",
            fact: "/new_map/done-table",
        },
        getTableColumnsSchemeData: "/map/state",
    };

    static _tableKeyPrefix = "table-reconciliation";

    static _tableColumnsTotalScheme = tableColumnsTotalScheme;

    static _tableColumnsScheme = tableColumnsScheme;

    static _tableRowsValuesTotalScheme = tableRowsValuesTotalScheme;

    static async _postTableColumnsSchemeData(
        reqBody = {},
        actionAfter = () => {},
        actionError = () => {}
    ) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.getTableColumnsSchemeData ?? "",
                reqBody,
            );
            const { data = {} } = res;
            const { data: resBody = {} } = data;

            actionAfter(resBody);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static async setTableColumnsSchemeData(
        pageType = "",
        tableColumns = [],
        actionAfter = () => {},
        actionError = () => {}
    ) {
        await this._setTableColumnsSchemeData(
            pageType,
            tableColumns,
            actionAfter,
            actionError
        );
    }

    static async _setTableColumnsSchemeData(
        pageType = "",
        tableColumns = [],
        actionAfter = () => {},
        actionError = () => {}
    ) {
        const reqBody = this._setTableColumnsSchemeDataBefore(
            pageType,
            tableColumns
        );
        await this._postTableColumnsSchemeData(
            reqBody,
            actionAfter,
            actionError
        );
    }

    static _setTableColumnsSchemeDataBefore(pageType = "", tableColumns = []) {
        const tableColumnsBefore = tableColumns.map((tableColumn) => {
            return {
                prop: String(tableColumn?.prop || ""),
                isShow: Boolean(tableColumn?.isShow || false),
            };
        });
        const tableKey = `${this._tableKeyPrefix}-${pageType}`;
        const reqBody = { mapState: { [tableKey]: tableColumnsBefore } };

        return reqBody;
    }

    static async _getTableScheme(
        pageType = "",
        columnsKeys = {},
        actionAfter = () => {},
        actionError = () => {}
    ) {
        const tableKey = `${this._tableKeyPrefix}-${pageType}`;

        await this._postTableColumnsSchemeData(
            {},
            (resBody) => {
                let tableColumnsSchemeFront =
                    this._tableColumnsScheme[pageType] ?? [];

                if (["approved", "fact"].includes(pageType)) {
                    tableColumnsSchemeFront = Object.keys(columnsKeys).reduce((colsAfter, colKey) => {
                        let currentColumn = this._tableColumnsScheme[pageType].find((item) => item?.apiProp === colKey);

                        if (currentColumn) {
                            currentColumn = { ...currentColumn, label: columnsKeys[colKey] };
                        } else {
                            currentColumn = { prop: colKey, apiProp: colKey, label: columnsKeys[colKey], isShow: true };
                        }

                        colsAfter.push(currentColumn);
                        return colsAfter;
                    }, []);

                    tableColumnsSchemeFront.push(this._tableColumnsScheme[pageType].find(item => item?.isAction))
                }

                let tableColumnsSchemeBack = resBody[tableKey] ?? [];

                const tableColumnsSchemeAdd = lodash.differenceBy(
                    tableColumnsSchemeFront,
                    tableColumnsSchemeBack,
                    "prop"
                );
                const tableColumnsSchemeRemove = lodash
                    .differenceBy(
                        tableColumnsSchemeBack,
                        tableColumnsSchemeFront,
                        "prop"
                    )
                    .map((column) => String(column?.prop ?? ""));
                lodash.remove(tableColumnsSchemeBack, (column) =>
                    tableColumnsSchemeRemove.includes(String(column.prop) ?? "")
                );

                const tableColumnsSchemeExtraData = [
                    ...tableColumnsSchemeBack,
                    ...tableColumnsSchemeAdd,
                ];
                const tableColumnsSchemeKeys = tableColumnsSchemeExtraData.map(
                    (tableColumnScheme) => String(tableColumnScheme?.prop ?? "")
                );

                let tableColumnsScheme = tableColumnsSchemeExtraData.map(
                    (tableColumnSchemeExtraData) => {
                        const prop = String(
                            tableColumnSchemeExtraData?.prop || ""
                        );
                        const tableColumnTotalScheme =
                            this._tableColumnsTotalScheme[prop] ?? {};
                        const tableColumnScheme = {
                            ...tableColumnTotalScheme,
                            ...tableColumnSchemeExtraData,
                        };

                        return tableColumnScheme;
                    }
                );

                tableColumnsScheme.sort((columnPrev, columnNext) => {
                    const {
                        isStickyLeft: isStickyLeftPrev = false,
                        isStickyRight: isStickyRightPrev = false,
                    } = columnPrev;
                    const {
                        isStickyLeft: isStickyLeftNext = false,
                        isStickyRight: isStickyRightNext = false,
                    } = columnNext;

                    if (isStickyLeftPrev || isStickyRightNext) return -1;
                    else if (isStickyRightPrev || isStickyLeftNext) return 1;
                    else return 0;
                });

                const tableRowsValuesScheme = tableColumnsSchemeKeys.reduce(
                    (tableRowsValuesScheme, columnSchemeKey) => {
                        tableRowsValuesScheme = {
                            ...tableRowsValuesScheme,
                            [columnSchemeKey]:
                                this._tableRowsValuesTotalScheme[
                                    columnSchemeKey
                                ],
                        };

                        return tableRowsValuesScheme;
                    },
                    {}
                );
                actionAfter({ tableColumnsScheme, tableRowsValuesScheme });
            },
            actionError
        );
    }

    static async getTable(
        pageType = "",
        filterData = [],
        kitId = "",
        page = 1,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        await this._getTable(
            pageType,
            filterData,
            kitId,
            page,
            actionAfter,
            actionError
        );
    }

    static async _getTable(
        pageType = "",
        filterData = [],
        kitId = "",
        page = 1,
        actionAfter = () => {},
        actionError = () => {}
    ) {
        const reqBody = this._getTableBefore(filterData, kitId, page);

        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.getTable[pageType] ?? "",
                reqBody
            );
            const { data = {} } = res;
            const { data: resBody = {} } = data;
            const { fields: columnsKeys = {}, editable } = resBody;

            await this._getTableScheme(
                pageType,
                columnsKeys,
                (tableScheme) => {
                    const {
                        tableColumnsScheme = [],
                        tableRowsValuesScheme = {},
                    } = tableScheme;
                    const tableData = this._getTableAfter(
                        tableColumnsScheme,
                        tableRowsValuesScheme,
                        resBody,
                        pageType,
                    );
                    actionAfter({...tableData, editable});
                },
                actionError
            );
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getTableBefore(filterData = [], kitId = "", page = 1) {
        const reqBody = {
            page: parseInt(page),
            ...ServiceReconciliationFilter.getFilterBefore(filterData),
        };

        return kitId === ""
            ? reqBody
            : { price_group_id: parseInt(kitId), ...reqBody };
    }

    static _getTableAfter(
        columnsScheme = [],
        rowsValuesScheme = {},
        resBody = {},
    ) {
        let {
            item_count: rowsCount = 0,
            items: rows = [],
            fields: columnsKeys = {},
        } = resBody;
        const pageCount = Math.ceil(parseInt(rowsCount) / rows.length);

        const rowsAfter = rows.map((row) => {
            let rowAfter = columnsScheme.reduce((rowAfter, column) => {
                const { prop = "" } = column;
                const getRowValue = rowsValuesScheme[prop] ?? (() => {});
                rowAfter[prop] = getRowValue(row) || row[prop];

                return rowAfter;
            }, {});
            rowAfter.extraData = { ...row };
            rowAfter.id = String(row?.id ?? "");

            return rowAfter;
        });

        for (const col in columnsKeys) {
            columnsScheme.forEach((item) => {
                if ([item.prop, item.apiProp].includes(col)) item.label = columnsKeys[col];
                return item;
            });
        }

        const tableData = {
            pageCount,
            rows: [...rowsAfter],
            columns: [...columnsScheme],
        };

        return tableData;
    }
}
