<template>
    <div class="reconciliation-filter-item">
        <BasePreloaderSkeleton
            v-if="isLoading"
            class="reconciliation-filter-item__preloader"
        />
        <DatePickerDropdown
            v-if="filterItemComponentName === 'DatePickerDropdown'"
            isMultiple
            :accessDates="filterItemAccessDates"
            :checkedDates="filterItemCheckedDates"
            :style="{width: '160px'}"
            position='left'
            @onApprove="onApproveDatesFilterItem"
        />
        <BaseTextInput
            v-if="filterItemComponentName === 'BaseTextInput'"
            isSearch
            :value="filterItemValueInput"
            :placeholder="filterItemDefaultTitle"
            :delayInputLazy="800"
            view="primary"
            @onInputLazy="onInputLazyFilterItem"
        />
        <BaseSelectMultipleV2
            v-if="filterItemComponentName === 'BaseSelectMultipleV2'"
            isFullWidth
            isOptionsFullWidth
            canSearch
            :style="{width: '160px'}"
            :options="filterItemValues"
            :value="filterItemValuesSelected"
            :defaultTitle="filterItemDefaultTitle"
            position="left"
            class="reconciliation-filter-item__component"
            @check="onSelectFilterItem"
        />
    </div>
</template>

<script>
import BaseSelectMultipleV2 from '../../Base/BaseSelectMultipleV2.vue';
import BaseTextInput from '../../Base/BaseTextInput.vue';
import DatePickerDropdown from '../../DatePickerDropdown.vue';
import BasePreloaderSkeleton from '../../Base/BasePreloaderSkeleton.vue';
import UtilDate from '../../../utils/UtilDate';
export default {
    name: 'ReconciliationFilterItem',
    components: {
        BaseSelectMultipleV2,
        BaseTextInput,
        DatePickerDropdown,
        BasePreloaderSkeleton
    },
    props: {
        filterItemId: {
            type: String,
            default: ''
        },
        filterItemComponentName: {
            type: String,
            default: ''
        },
        filterItemValues: {
            type: Array,
            default: () => ([])
        },
        filterItemValuesSelected: {
            type: Array,
            default: () => ([])
        },
        filterItemDefaultTitle: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        filterItemValueInput() {
            const filterItemValueInput = this.filterItemValuesSelected[0] ?? '';
            return this.filterItemComponentName !== 'BaseTextInput' ? '' : String(filterItemValueInput);
        },
        filterItemAccessDates() {
            const filterItemAccessDates = this.filterItemValues.map(value => UtilDate.getDatePickerObj(String(value?.value ?? '')));
            return this.filterItemComponentName !== 'DatePickerDropdown' ? [] : filterItemAccessDates;
        },
        filterItemCheckedDates() {
            const filterItemCheckedDates = this.filterItemValuesSelected.map(value => UtilDate.getDatePickerObj(String(value)));
            return this.filterItemComponentName !== 'DatePickerDropdown' ? [] : filterItemCheckedDates;
        }
    },
    methods: {
        onSelectFilterItem(filterComponentItems = []) {
            console.log('onSelectFilterItem');
            console.log(filterComponentItems);
            this.$emit('onSelectFilterItem', filterComponentItems, this.filterItemId);
        },
        onInputLazyFilterItem(value = '') {
            this.onSelectFilterItem([String(value)]);
        },
        onApproveDatesFilterItem(dates = []) {
            const datesSelected = dates.map(date => UtilDate.fromDatepickerDate(date));
            this.onSelectFilterItem(datesSelected);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-filter-item {
        position: relative;

        // &__component::v-deep .select-preview {
        //     padding: 10px 20px;
        // }

        &__preloader {
            z-index: 1;
            border-radius: 10px;
        }
    }
</style>
